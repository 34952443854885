import { css } from "@emotion/react";
import {
  colors,
  breakpoints,
} from "@mytutor/shared-react-web-components/lib/theme";

import Carousel from "../Carousel";

const ratingsStyle = css`
  > p,
  > div {
    max-width: 1265px;
    margin: 0 auto;

    @media (max-width: 767px) {
      max-width: 460px;
      margin: 0 auto 0;
    }
  }
  > p {
    font-size: 46px;
    font-size: 2.875rem;
    line-height: 56px;
    line-height: 3.5rem;
    font-weight: 600;
    letter-spacing: 0;
    color: ${colors.primary7};
    text-align: center;
    margin: 0 auto;
    padding: 40px 0 0;

    @media (max-width: 767px) {
      font-size: 2.125rem;
    }

    @media (max-width: ${breakpoints.tablet}px) {
      font-size: 1.9rem;
    }
  }
`;

const Ratings = () => (
  <div css={ratingsStyle}>
    <Carousel />
  </div>
);

export default Ratings;
