import React from "react";
import { css } from "@emotion/react";
import Image from "next/image";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

import {
  colors,
  breakpoints,
} from "@mytutor/shared-react-web-components/lib/theme";

import reviewData from "./data";

const sliderStyles = css`
  padding: 40px;

  .navigation-wrapper {
    position: relative;
  }

  .carousel-header {
    display: flex;

    @media (max-width: ${breakpoints.tablet}px) {
      flex-direction: column;
    }

    h2 {
      font-size: 2.5em;
      font-weight: 500;
      line-height: 1.25em;
      max-width: 700px;

      @media (max-width: ${breakpoints.desktop}px) {
        font-size: 1.5em;
        line-height: 1.25em;
        max-width: 450px;
      }
    }

    div {
      margin: auto 0 auto auto;
      @media (max-width: ${breakpoints.tablet}px) {
        margin: auto 0 10px auto;
      }

      svg {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .dots {
    display: flex;
    padding: 25px 0;
    justify-content: center;
  }

  .dot {
    border: 1px solid #9da9ae;
    width: 12px;
    height: 12px;
    background: ${colors.neutral1};
    border-radius: 50%;
    margin: 0 10px;
    padding: 3px;
    cursor: pointer;
    opacity: 0.3;
    transition: 0.05s ease-out opacity;
  }

  .dot:focus {
    outline: none;
    opacity: 1;
  }

  .dot.active {
    background: ${colors.neutral1};
    opacity: 1;
  }

  .arrow {
    width: 3em;
    height: 3em;
    padding: 0.75em;
    cursor: pointer;
    background: ${colors.neutral1};
    border-radius: 100%;
  }

  .arrow--left {
    left: 5px;
  }

  .arrow--right {
    left: auto;
    right: 5px;
  }
`;

const backgroundImages = [
  {
    url: "https://cdn.mytutor.co.uk/images/hero/bg-triangle-topright-secondary5.svg?1630494614000",
    position: "top right",
    size: "75%",
  },
  {
    url: "https://cdn.mytutor.co.uk/images/hero/bg-hexagon-topleft-accent4.svg?1630494614000",
    position: "top left",
    size: "75%",
  },
  {
    url: "https://cdn.mytutor.co.uk/images/hero/bg-donut-topright-primary5.svg?1630494614000",
    position: "top right",
    size: "100%",
  },
  {
    url: "https://cdn.mytutor.co.uk/images/hero/bg-plus-bottomleft-tertiary5.svg?1630494614000",
    position: "bottom left",
    size: "75%",
  },
];

const reviewStyles = (backgroundPictureIdx) => css`
  border-radius: 2%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.bgcolor2};

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e9eaeb;
    background-image: url(${backgroundImages[backgroundPictureIdx].url});
    background-position: ${backgroundImages[backgroundPictureIdx].position};
    background-size: ${backgroundImages[backgroundPictureIdx].size};
    background-repeat: no-repeat;

    span:first-of-type {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      margin: 40% auto 0 auto;
    }

    div img {
      height: 16px;
      width: 84px;
    }

    div {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
    }

    p {
      margin: 0;
    }

    p:first-of-type {
      font-weight: bold;
    }

    p:nth-of-type(2) {
      color: #67657d;
    }
  }

  > div:nth-of-type(2) {
    margin-bottom: 10px;
    padding: 20px 26px 30px 26px;
    p {
      text-align: center;
    }
    p:nth-of-type(1) {
      font-size: 1.688em;
      font-weight: 500;
    }
    span {
      display: block;
      font-weight: 500;
      font-size: 0.5em;
    }
  }
`;

const Review = ({
  imageUrl,
  userTitle,
  parentName,
  quote,
  backgroundPictureIdx,
}) => (
  <div css={reviewStyles(backgroundPictureIdx)} className="keen-slider__slide">
    <div>
      <span>
        <Image src={imageUrl} alt={"Tutora avatar"} width={72} height={72} />
      </span>
    </div>
    <div>
      <p>
        {parentName} <span>{userTitle}</span>
      </p>
      <p>&quot;{quote}&quot;</p>
    </div>
  </div>
);

const Carousel = () => {
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slidesPerView: 3,
    spacing: 30,
    loop: true,
    breakpoints: {
      [`(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop}px)`]:
        {
          slidesPerView: 2,
        },
      [`(max-width: ${breakpoints.tablet}px)`]: {
        slidesPerView: 1,
      },
    },
  });

  return (
    <div css={sliderStyles}>
      <div className="navigation-wrapper">
        <div className="carousel-header">
          <h2>
            Thousands of parents, students and teachers have rated us 4.96/5
          </h2>
          {slider && (
            <div>
              <ArrowLeft
                onClick={(e) => e.stopPropagation() || slider.prev()}
              />
              <ArrowRight
                onClick={(e) => e.stopPropagation() || slider.next()}
              />
            </div>
          )}
        </div>
        <div ref={sliderRef} className="keen-slider">
          {reviewData.map(({ imageUrl, parentName, quote, userTitle }, idx) => (
            <Review
              key={`${parentName}`}
              imageUrl={imageUrl}
              parentName={parentName}
              quote={quote}
              userTitle={userTitle}
              backgroundPictureIdx={idx % 4}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

function ArrowLeft({ onClick }) {
  return (
    <svg
      onClick={onClick}
      className="arrow arrow--left"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3518 12.7432C23.7178 12.6935 24 12.3797 24 12C24 11.5858 23.6642 11.25 23.25 11.25L2.56098 11.25L11.7803 2.03033L11.8529 1.94621C12.0708 1.6526 12.0466 1.23594 11.7803 0.96967C11.4874 0.676777 11.0126 0.676777 10.7197 0.96967L0.219669 11.4697C0.19364 11.4957 0.169512 11.5236 0.147518 11.5532L0.147053 11.5538C0.109995 11.6037 0.0799389 11.6572 0.0568886 11.713C0.0245094 11.7911 0.00494576 11.8758 0.00082016 11.9646C0.000274658 11.9763 0 11.9881 0 12C0 12.1919 0.0732231 12.3839 0.219669 12.5303L10.7197 23.0303L10.8038 23.1029C11.0974 23.3208 11.5141 23.2966 11.7803 23.0303L11.8529 22.9462C12.0708 22.6526 12.0466 22.2359 11.7803 21.9697L2.56098 12.75L23.25 12.75L23.3518 12.7432Z"
        fill="#232323"
      />
    </svg>
  );
}

function ArrowRight({ onClick }) {
  return (
    <svg
      onClick={onClick}
      className="arrow arrow--right"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.648229 11.2568C0.282154 11.3065 0 11.6203 0 12C0 12.4142 0.335786 12.75 0.75 12.75L21.439 12.75L12.2197 21.9697L12.1471 22.0538C11.9292 22.3474 11.9534 22.7641 12.2197 23.0303C12.5126 23.3232 12.9874 23.3232 13.2803 23.0303L23.7803 12.5303C23.8064 12.5043 23.8305 12.4764 23.8525 12.4468L23.8529 12.4462C23.89 12.3963 23.9201 12.3428 23.9431 12.287C23.9755 12.2089 23.9951 12.1242 23.9992 12.0354C23.9997 12.0237 24 12.0119 24 12C24 11.8081 23.9268 11.6161 23.7803 11.4697L13.2803 0.969669L13.1962 0.897051C12.9026 0.679197 12.4859 0.703403 12.2197 0.969669L12.1471 1.05379C11.9292 1.3474 11.9534 1.76406 12.2197 2.03033L21.439 11.25L0.75 11.25L0.648229 11.2568Z"
        fill="#232323"
      />
    </svg>
  );
}

export default Carousel;
