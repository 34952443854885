const data = [
  {
    imageUrl: "https://cdn.mytutor.co.uk/images/uploads/natalyam.jpg",
    parentName: "Natalya M.",
    userTitle: "Parent",
    quote:
      "It is SO positive to have a mentor in the same peer group and really understanding what it's like to be a student now . Our tutor has really helped with both exam technique and confidence.",
  },
  {
    imageUrl:
      "https://cdn.mytutor.co.uk/images/tutor-profiles/7293.jpg?pfdrid_c=true",
    parentName: "Sharon T.",
    userTitle: "Parent",
    quote:
      "Millie achieved an A in chemistry! We are thrilled as she was predicted a D in February. Thank you so much for your help and support.",
  },
  {
    imageUrl:
      "https://cdn.mytutor.co.uk/images/tutor-profiles/20509.jpg?pfdrid_c=true",
    parentName: "Natasha T.",
    userTitle: "Parent",
    quote:
      "Having a peer mentor made a huge difference to my daughter: built her confidence, motivated her and gave her the tools and belief that she could do it. And she did! She's off to university in September, thank you!",
  },
  {
    imageUrl: "https://cdn.mytutor.co.uk/images/uploads/reviewcard-Damianb.jpg",
    parentName: "Damian B.",
    userTitle: "Deputy Headteacher",
    quote:
      "MyTutor is a core part of our intervention strategy. In 2017, we achieved record results and were the second most improved school in the country",
  },
  {
    imageUrl: "https://cdn.mytutor.co.uk/images/uploads/reviewcard-Clare.jpg",
    parentName: "Clare T.",
    userTitle: "Pupil Premium Coordinator",
    quote:
      "One-to-one tuition gives students the chance to develop confidence and ask questions that they wouldn't feel comfortable asking in a classroom setting",
  },
  {
    imageUrl:
      "https://cdn.mytutor.co.uk/images/uploads/reviewcard-Michelle.jpg",
    parentName: "Michelle O.",
    userTitle: "Deputy Headteacher",
    quote:
      "MyTutor enables our students to work at their own pace, at their own level, and have that one-to-one tuition that they need to take them forward.",
  },
  {
    imageUrl:
      "https://cdn.mytutor.co.uk/images/uploads/reviewcard-Sharleen.jpg",
    parentName: "Sharleen",
    userTitle: "GCSE Student",
    quote:
      "When I'm having tutoring, I get to be alone in my own space and I can ask any question I want without anyone interrupting me",
  },
  {
    imageUrl: "https://cdn.mytutor.co.uk/images/uploads/reviewcard-Phoebe.jpg",
    parentName: "Phoebe",
    userTitle: "A-level Student",
    quote:
      "I like MyTutor because I get one-to-one support which I don't get in a classroom of 30 kids",
  },
  {
    imageUrl: "https://cdn.mytutor.co.uk/images/uploads/reviewcard-Tom.jpg",
    parentName: "Tom",
    userTitle: "GCSE Student",
    quote:
      "I really enjoy learning with my tutor every week. She builds my confidence up - if I get something wrong, she tells me where I went wrong and how to do it better.",
  },
];

export default data;
